var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_invoice_ap") } },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-form",
                _vm._b(
                  { attrs: { form: _vm.form } },
                  "a-form",
                  _vm.formItemLayout,
                  false
                ),
                [_c("invoice-ap-header")],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-tabs",
                {
                  attrs: {
                    "default-active-key": _vm.activeTabPane,
                    activeKey: _vm.activeTabPane,
                    type: "card"
                  },
                  on: { change: _vm.handleChangeMenu }
                },
                _vm._l(_vm.columnTabPane, function(item) {
                  return _c("a-tab-pane", { key: item }, [
                    _c(
                      "span",
                      { attrs: { slot: "tab" }, slot: "tab" },
                      [
                        _vm._v(" " + _vm._s(item) + " "),
                        _c("a-icon", {
                          staticStyle: { color: "red" },
                          attrs: { type: "tag" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeTabPane === "Details",
                            expression: "activeTabPane === 'Details'"
                          }
                        ],
                        staticClass: "pt-4"
                      },
                      [_c("TabDetailsInvoiceAp")],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeTabPane === "Tax Details",
                            expression: "activeTabPane === 'Tax Details'"
                          }
                        ],
                        staticClass: "pt-4"
                      },
                      [
                        _c(
                          "a-form",
                          _vm._b(
                            { attrs: { layout: "vertical", form: _vm.form } },
                            "a-form",
                            _vm.formItemLayout,
                            false
                          ),
                          [_c("TabTaxDetailsInvoiceAp")],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeTabPane === "Apply Prepayment",
                            expression: "activeTabPane === 'Apply Prepayment'"
                          }
                        ],
                        staticClass: "pt-4"
                      },
                      [_c("TabApplyPrepaymentInvoiceAp")],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeTabPane === "Status",
                            expression: "activeTabPane === 'Status'"
                          }
                        ],
                        staticClass: "pt-4"
                      },
                      [
                        _c(
                          "a-form",
                          _vm._b(
                            { attrs: { layout: "vertical", form: _vm.form } },
                            "a-form",
                            _vm.formItemLayout,
                            false
                          ),
                          [_c("TabStatusInvoiceAp")],
                          1
                        )
                      ],
                      1
                    )
                  ])
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { type: "flex", gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleBack } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                  ]),
                  (_vm.isModeCreate || _vm.allowToSaveDraft) &&
                  _vm.$can("create", "invoice-ap") &&
                  _vm.$can("update", "invoice-ap")
                    ? _c(
                        "a-button",
                        {
                          attrs: { loading: _vm.loading.submit },
                          on: {
                            click: function($event) {
                              return _vm.onSubmit("draft")
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("lbl_save_as_draft")) + " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.allowToCancel && _vm.$can("cancel", "invoice-ap")
                    ? [
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              title: _vm.$t("pop_confirmation"),
                              "ok-text": _vm.$t("lbl_yes"),
                              "cancel-text": _vm.$t("lbl_no")
                            },
                            on: {
                              confirm: function($event) {
                                return _vm.handleCancelDocument(_vm.invoiceAPId)
                              }
                            }
                          },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: {
                                  type: "danger",
                                  loading: _vm.loading.cancel
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _vm.showSubmit &&
                  _vm.$can("update", "invoice-ap") &&
                  _vm.$can("create", "invoice-ap")
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.submit
                          },
                          on: {
                            click: function($event) {
                              return _vm.onSubmit("submit")
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                      )
                    : _vm._e(),
                  _vm.allowToApprove && _vm.$can("approve", "invoice-ap")
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.approve
                          },
                          on: {
                            click: function($event) {
                              return _vm.onSubmit("approve")
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_approve")) + " ")]
                      )
                    : _vm._e(),
                  _vm.allowToApprove && _vm.$can("approve", "invoice-ap")
                    ? [
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              title: _vm.$t("pop_confirmation"),
                              "ok-text": _vm.$t("lbl_yes"),
                              "cancel-text": _vm.$t("lbl_no")
                            },
                            on: {
                              confirm: function($event) {
                                return _vm.handleRejectDocument(_vm.invoiceAPId)
                              }
                            }
                          },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: {
                                  type: "danger",
                                  loading: _vm.loading.reject
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("lbl_reject")) + " ")]
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }