













































































































import { validateTable } from "@/helpers/common";
import MNotificationVue from "@/mixins/MNotification.vue";
import MValidationVue from "@/mixins/MValidationTranslate.vue";
import { Mode } from "@/models/enums/global.enum";
import { INVOICE_AP_STATUS } from "@/models/enums/invoice-ap.enum";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  InvoiceAPCreateDTO,
  InvoiceAPResponse,
  InvoiceAPUpdateDTO,
} from "@/models/interface/invoiceAp.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { invoiceAPServices } from "@/services/invoiceAp.service";
import { IForm, InvoiceAPLine } from "@/store/resources/invoice-ap.resource";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import Decimal from "decimal.js-light";
import moment from "moment";
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapActions, mapMutations, mapState } from "vuex";

type SubmitType = "update" | "submit" | "approve" | "draft";

const ALLOW_TO_SAVE_DRAFT: INVOICE_AP_STATUS[] = [INVOICE_AP_STATUS.DRAFT];

const ALLOW_TO_UPDATE: INVOICE_AP_STATUS[] = [
  INVOICE_AP_STATUS.DRAFT,
  INVOICE_AP_STATUS.NEED_APPROVAL,
];

const ALLOW_TO_APPROVE: INVOICE_AP_STATUS[] = [INVOICE_AP_STATUS.NEED_APPROVAL];

const ALLOW_TO_CANCEL: INVOICE_AP_STATUS[] = [
  INVOICE_AP_STATUS.DRAFT,
  INVOICE_AP_STATUS.UNPAID,
];

const ALLOW_TO_SUBMIT: INVOICE_AP_STATUS[] = [...ALLOW_TO_UPDATE];

const FORM_TRANSLATE_KEY = {
  source: "lbl_source",
  supplierName: "lbl_supplier_name",
  supplierShipAddress: "lbl_supplier_ship_address",
  supplierBillAddress: "lbl_supplier_bill_address",
  termOfPayment: "lbl_term_of_payment",
  invoiceSupplierNo: "lbl_invoice_supplier_number",
  invoiceDate: "lbl_invoice_date",
  accountingDate: "lbl_accounting_date",
  invoiceReceivedDate: "lbl_invoice_received_date",
  payablesAccount: "lbl_payables_account",
  invoiceDescription: "lbl_invoice_description",
  taxInvoiceDate: "lbl_tax_invoice_date",
  branch: "lbl_branch",
  currency: "lbl_currency",
};

const TABLE_TRANSLATE_KEY = {
  expenseAccountId: "lbl_expense_account",
  merk: "lbl_merk",
  partName: "lbl_part_name",
  partNumber: "lbl_part_number",
  price: "lbl_price",
  qty: "lbl_qty",
  taxCode: "lbl_tax_code",
  uom: "lbl_uom",
};

const REQUIRED_TABLE_COLUMN = [
  "expenseAccountId",
  "merk",
  "partName",
  "partNumber",
  "price",
  "qty",
  "taxCode",
  "uom",
];

/**
 * TODO:
 * 1. integrasi tab status
 * - return invoice -> total return amount dan lines [ nunggu backend buat return supplier dulu ]
 * 2. nambahin rule buat munculin pph berdasarkan field source [SPP-1950]
 */

@Component({
  mixins: [MNotificationVue, MValidationVue],
  provide() {
    return {
      headerForm: this.form,
    };
  },
  computed: {
    ...mapState({
      storeAPForm: (store: any) => store.invoiceApStore.form as IForm,
      storeInvoiceAPLines: (store: any) =>
        store.invoiceApStore.invoiceAPLines as InvoiceAPLine[],
    }),
  },
  methods: {
    ...mapMutations({
      setAPForm: "invoiceApStore/SET_FORM_HEADER",
      setDetailInvoiceAP: "invoiceApStore/SET_DETAIL_INVOICE_AP",
      setInvoiceAPPrepayments: "invoiceApStore/SET_INVOICE_AP_PREPAYMENTS",
      setInvoiceAPDeletedLine: "invoiceApStore/SET_INVOICE_AP_DELETED_LINE",
    }),
    ...mapActions({
      resetStoreAP: "invoiceApStore/RESET_STATE",
      constructPayloadCreate: "invoiceApStore/CONSTRUCT_PAYLOAD_CREATE",
      constructPayloadUpdate: "invoiceApStore/CONSTRUCT_PAYLOAD_UPDATE",
      mapDetailToForm: "invoiceApStore/MAP_DETAIL_TO_FORM",
      mapDetailToTable: "invoiceApStore/MAP_DETAIL_TO_TABLE",
      sumPrepayment: "invoiceApStore/SUM_PREPAYMENT",
      sumTax: "invoiceApStore/SUM_TAX",
      mapSummary: "invoiceApStore/MAP_SUMMARY",
    }),
  },
})
export default class CreateInvoiceAp extends Vue {
  form!: WrappedFormUtils;
  mode: string = Mode.CREATE;
  params: RequestQueryParamsModel = {};
  loading = {
    cancel: false,
    submit: false,
    approve: false,
    reject: false,
  };
  dataBranch: DataWarehouseBranch[] = [];
  dataSupplier: ContactData[] = [];
  columnTabPane: string[] = [];
  activeTabPane = "Details";
  currencyFrom = "IDR";
  currencyTo = "IDR";
  invoiceAPId = "";

  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: "CreateInvoicesAp" });
  }

  created(): void {
    this.initForm();
    this.columnTabPane = [
      "Details",
      "Tax Details",
      "Apply Prepayment",
      "Status",
    ];
  }

  mounted(): void {
    this.mode = this.$route.meta.mode;
    if (this.$route.params.id) {
      this.invoiceAPId = this.$route.params.id;
      this.getDetail(this.invoiceAPId);
    }
  }

  beforeDestroy(): void {
    this.$store.dispatch("invoiceApStore/RESET_STATE");
  }

  get allowToSaveDraft(): boolean {
    return ALLOW_TO_SAVE_DRAFT.includes(this.storeAPForm.statusInvoice);
  }

  get allowToUpdate(): boolean {
    return ALLOW_TO_UPDATE.includes(this.storeAPForm.statusInvoice);
  }

  get allowToApprove(): boolean {
    return ALLOW_TO_APPROVE.includes(this.storeAPForm.statusInvoice);
  }

  get allowToCancel(): boolean {
    return ALLOW_TO_CANCEL.includes(this.storeAPForm.statusInvoice);
  }

  get showSubmit(): boolean {
    return (
      ALLOW_TO_SUBMIT.includes(this.storeAPForm.statusInvoice) ||
      this.isModeCreate
    );
  }

  get isDraft(): boolean {
    return this.storeAPForm.statusInvoice === INVOICE_AP_STATUS.NEED_APPROVAL;
  }

  get isModeEdit(): boolean {
    return this.mode === Mode.EDIT;
  }

  get isModeCreate(): boolean {
    return this.mode === Mode.CREATE;
  }

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }

  get supplierShipAddress(): string {
    return this.storeAPForm.supplierShipAddress;
  }

  get supplierBillAddress(): string {
    return this.storeAPForm.supplierBillAddress;
  }

  get currencyRate(): string {
    return this.storeAPForm.rate;
  }

  get currency(): string {
    return this.storeAPForm.currency;
  }

  get taxRegistrationName(): string {
    return this.storeAPForm.taxRegistrationName;
  }

  get taxRegistrationNumber(): string {
    return this.storeAPForm.taxRegistrationNumber;
  }

  get taxType(): string {
    return this.storeAPForm.taxType;
  }

  get termOfPayment(): string {
    return this.storeAPForm.termOfPayment;
  }

  get payablesAccount(): string {
    return this.storeAPForm.payablesAccount;
  }

  get supplierName(): string {
    return this.storeAPForm.supplierName;
  }

  get source(): string {
    return this.storeAPForm.source;
  }

  @Watch("storeAPForm.supplierBillAddress")
  onChangeStoreSupplierBillAddress(newVal: string): void {
    this.form.setFieldsValue({
      supplierBillAddress: newVal,
    });
  }

  @Watch("storeAPForm.supplierShipAddress")
  onChangeStoreSupplierShipAddress(newVal: string): void {
    this.form.setFieldsValue({
      supplierShipAddress: newVal,
    });
  }

  @Watch("storeAPForm.rate")
  onChangeStoreCurrencyRate(newVal: string): void {
    this.form.setFieldsValue({
      rate: newVal,
    });
  }

  @Watch("storeAPForm.currency")
  onChangeStoreCurrency(newVal: string): void {
    this.form.setFieldsValue({
      currency: newVal,
    });
  }

  @Watch("storeAPForm.taxRegistrationNumber")
  onChangeTaxRegistrationNumber(newVal: string): void {
    this.form.setFieldsValue({
      taxRegistrationNumber: newVal,
    });
  }

  @Watch("storeAPForm.taxRegistrationName")
  onChangeTaxRegistrationName(newVal: string): void {
    this.form.setFieldsValue({
      taxRegistrationName: newVal,
    });
  }

  @Watch("storeAPForm.taxType")
  onChangeTaxType(newVal: string): void {
    this.form.setFieldsValue({
      taxType: newVal,
    });
  }

  @Watch("storeAPForm.termOfPayment")
  onChangeTermOfPayment(newVal: string): void {
    this.form.setFieldsValue({
      termOfPayment: newVal,
    });
  }

  @Watch("storeAPForm.payablesAccount")
  onChangePayablesAccount(newVal: string): void {
    this.form.setFieldsValue({
      payablesAccount: newVal,
    });
  }

  @Watch("storeAPForm.supplierName")
  onChangeSupplierName(newVal: string): void {
    this.form.setFieldsValue({
      supplierName: newVal,
    });
  }

  @Watch("storeAPForm.source")
  onChangeSource(newVal: string): void {
    this.form.resetFields();
    this.form.setFieldsValue({
      source: newVal,
    });
  }

  initForm(): void {
    this.form = this.$form.createForm(this, {
      name: "CreateInvoicesAp",
      onFieldsChange: (_, changedFields) => {
        this.$emit("change", changedFields);
      },
      mapPropsToFields: () => {
        return {
          supplierShipAddress: this.$form.createFormField({
            value: this.supplierShipAddress,
          }),
          supplierBillAddress: this.$form.createFormField({
            value: this.supplierBillAddress,
          }),
          rate: this.$form.createFormField({
            value: this.currencyRate,
          }),
          currency: this.$form.createFormField({
            value: this.currency,
          }),
          taxRegistrationName: this.$form.createFormField({
            value: this.taxRegistrationName,
          }),
          taxRegistrationNumber: this.$form.createFormField({
            value: this.taxRegistrationNumber,
          }),
          taxType: this.$form.createFormField({
            value: this.taxType,
          }),
          termOfPayment: this.$form.createFormField({
            value: this.termOfPayment,
          }),
          payablesAccount: this.$form.createFormField({
            value: this.payablesAccount,
          }),
          supplierName: this.$form.createFormField({
            value: this.supplierName,
          }),
          source: this.$form.createFormField({
            value: this.source,
          }),
        };
      },
      onValuesChange: (_, values) => {
        // Synchronize to vuex store in real time
        this.$store.commit("invoiceApStore/SET_FORM_HEADER", {
          ...this.storeAPForm,
          ...values,
        });
      },
    });
  }

  handleBack(): void {
    // this.$router.push("/account-payables/invoices/invoice-ap/list");
    this.$router.push({
      name: "account-payables.invoice-ap.list",
      query: { ...this.$route.query },
    });
  }

  onSubmit(type: SubmitType): void {
    this.form.validateFields((err, value) => {
      // validate table
      if (!this.storeInvoiceAPLines.length) {
        this.showNotifError("lbl_invoice_lines_is_mandatory");
        return;
      }

      const [isInvalid, columns] = validateTable<InvoiceAPLine>(
        this.storeInvoiceAPLines as InvoiceAPLine[],
        REQUIRED_TABLE_COLUMN
      );

      if (isInvalid) {
        // show notif
        const message = this.doTranslateColumns(columns, TABLE_TRANSLATE_KEY);
        this.showNotifError("notif_column_is_mandatory", { column: message });
        return;
      }

      if (err) {
        const message = this.doTranslateFields(err, FORM_TRANSLATE_KEY);
        this.showNotifError("notif_field_is_mandatory", { field: message });
        return;
      }

      switch (type) {
        case "submit":
          if (
            this.invoiceAPId &&
            value.statusInvoice === INVOICE_AP_STATUS.DRAFT
          ) {
            this.handleSubmitDocument(this.invoiceAPId);
          } else if (this.invoiceAPId) {
            this.handleUpdateDocument(this.invoiceAPId);
          } else {
            this.handleSubmitNewDocument();
          }
          break;
        case "approve":
          this.handleApproveDocument(this.invoiceAPId);
          break;
        case "draft":
          if (this.invoiceAPId) {
            this.handleUpdateDocument(this.invoiceAPId);
          } else {
            this.handleSaveAsDraft();
          }
          break;
        default:
          break;
      }
    });
  }

  /**
   * handle click save as draft
   */
  async handleSaveAsDraft(): Promise<void> {
    try {
      this.loading.submit = true;
      const payload: InvoiceAPCreateDTO = await this.constructPayloadCreate({
        status: INVOICE_AP_STATUS.DRAFT,
      });
      const { documentNumber, id } = await invoiceAPServices.createApInvoice(
        payload
      );
      this.showNotifSuccess("notif_document_created_as_draft_success", {
        documentNumber,
      });
      this.$router.push({
        name: "account-payables.invoice-ap.edit",
        params: { id },
      });
    } catch (error) {
      this.showNotifError("notif_submit_fail");
    } finally {
      this.loading.submit = false;
    }
  }

  /**
   * handle click submit without existing document
   */
  async handleSubmitNewDocument(): Promise<void> {
    try {
      this.loading.submit = true;
      const payload: InvoiceAPCreateDTO = await this.constructPayloadCreate({
        status: INVOICE_AP_STATUS.NEED_APPROVAL,
      });
      const { documentNumber, id } = await invoiceAPServices.createApInvoice(
        payload
      );
      this.showNotifSuccess("notif_document_created_as_submit_success", {
        documentNumber,
      });
      this.$router.push({
        name: "account-payables.invoice-ap.edit",
        params: { id },
      });
    } catch (error) {
      this.showNotifError("notif_submit_fail");
    } finally {
      this.loading.submit = false;
    }
  }

  /**
   * handle click submit with existing document
   * @param id invoice ap id
   */
  async handleSubmitDocument(docId: string): Promise<void> {
    try {
      this.loading.submit = true;
      const payload: InvoiceAPUpdateDTO = await this.constructPayloadUpdate({
        status: INVOICE_AP_STATUS.NEED_APPROVAL,
      });
      const { documentNumber, id } = await invoiceAPServices.submitApInvoice(
        docId,
        payload
      );
      this.showNotifSuccess("notif_submit_success", { documentNumber });
      this.getDetail(id);
    } catch (error) {
      this.showNotifError("notif_submit_fail");
    } finally {
      this.loading.submit = false;
    }
  }

  /**
   * handle update invoice
   */
  async handleUpdateDocument(docId: string): Promise<void> {
    try {
      this.loading.submit = true;
      const payload: InvoiceAPUpdateDTO = await this.constructPayloadUpdate({
        status: this.storeAPForm.statusInvoice,
      });
      const { documentNumber, id } = await invoiceAPServices.updateApInvoice(
        docId,
        payload
      );
      this.showNotifSuccess("notif_update_success", { documentNumber });
      this.setInvoiceAPDeletedLine([]);
      this.getDetail(id);
    } catch (error) {
      this.showNotifError("notif_update_fail");
    } finally {
      this.loading.submit = false;
    }
  }

  /**
   * handle click cancel
   * @param id invoice ap id
   */
  async handleCancelDocument(docId: string): Promise<void> {
    try {
      this.loading.cancel = true;
      const { documentNumber } = await invoiceAPServices.cancelApInvoice(docId);
      this.showNotifSuccess("notif_cancel_success", { documentNumber });
      this.$router.push({
        name: "account-payables.invoice-ap.list",
        query: { ...this.$route.query },
      });
    } catch (error) {
      this.showNotifError("notif_cancel_fail");
    } finally {
      this.loading.cancel = false;
    }
  }

  /**
   * handle click approve
   * @param id invoice ap id
   */
  async handleApproveDocument(docId: string): Promise<void> {
    try {
      this.loading.approve = true;
      const payload: InvoiceAPUpdateDTO = await this.constructPayloadUpdate({
        status: INVOICE_AP_STATUS.UNPAID,
      });
      const { documentNumber } = await invoiceAPServices.approveApInvoice(
        docId,
        payload
      );
      this.showNotifSuccess("notif_approve_success", { documentNumber });
      this.$router.push({
        name: "account-payables.invoice-ap.list",
        query: { ...this.$route.query },
      });
    } catch (error) {
      this.showNotifError("notif_approve_fail");
    } finally {
      this.loading.approve = false;
    }
  }

  /**
   * handle click reject
   * @param id invoice ap id
   */
  async handleRejectDocument(docId: string): Promise<void> {
    try {
      this.loading.reject = true;
      const { documentNumber } = await invoiceAPServices.rejectApInvoice(docId);
      this.showNotifSuccess("notif_reject_success", { documentNumber });
      this.$router.push({
        name: "account-payables.invoice-ap.list",
        query: { ...this.$route.query },
      });
    } catch (error) {
      this.showNotifError("notif_reject_fail");
    } finally {
      this.loading.reject = false;
    }
  }

  /**
   * get detail invoice ap
   * @param id invoice ap id
   */
  async getDetail(id: string): Promise<void> {
    try {
      const detail: InvoiceAPResponse = await invoiceAPServices.detailApInvoice(
        id
      );
      this.setDetailInvoiceAP(detail);
      this.mapDetailToForm({ detail });
      this.setForm(detail);
      this.mapDetailToTable({ detail });
      this.mapSummary({ detail });
      this.setInvoiceAPPrepayments({
        prepaymentLines: detail.applyPrepayment.prepaymentLines,
      });
    } catch (error) {
      this.showNotifError("notif_process_fail");
    }
  }

  setForm(detail: InvoiceAPResponse): void {
    const values: IForm = {
      source: detail.invoiceSource,
      branch: detail.branchWarehouseId,
      currency: detail.currency,
      supplierName: detail.supplierId,
      supplierShipAddress: detail.supplierShipToAddress,
      supplierBillAddress: detail.supplierBillToAddress,
      termOfPayment: detail.termOfPayment.toString(),
      findPurchaseOrder: detail.purchaseOrders.map<string>(item => item.id),
      findGoodReceipt: detail.goodReceipts.map<string>(item => item.id),
      taxType: detail.taxType,
      invoiceNumber: detail.documentNumber,
      invoiceSupplierNo: detail.invoiceSupplierNo,
      invoiceDate: moment(detail.invoiceDate).format(),
      accountingDate: detail.accountingDate,
      invoiceReceivedDate: detail.invoiceReceivedDate,
      rate: detail.currencyRate,
      journalNumber: detail.journalNo,
      journalId: detail.journalId,
      payablesAccount: detail.payablesAccountId,
      invoiceDescription: detail.description,
      statusInvoice: detail.status,
      taxRegistrationNumber: detail.taxRegistrationNumber,
      taxRegistrationName: detail.taxRegistrationName,
      taxInvoiceDate: moment(detail.taxInvoiceDate).format(),
      taxInvoiceNumber: detail.taxInvoiceNumber,
      total: detail.grandTotal,
      prepaymentUsed: detail.prepaymentUsed,
      remainingInvoiceAmount: detail.remainingInvoiceAmount,
      apPayment: detail.invoiceAPPaymentDetails.reduce(
        (a, b) => new Decimal(b.paymentAmount).plus(a).toNumber(),
        0
      ),
    };

    for (const key in values) {
      this.form.getFieldDecorator(key, {
        initialValue: values[key],
      });
    }
    this.form.setFieldsValue(values);
  }

  handleChangeMenu(value: string): void {
    this.activeTabPane = value;
  }
}
