
import { ValidationTableType } from "@/models/constant/interface/common.interface";
import { PropsModel } from "@/models/interfaces/common.interface";
import Vue from "vue";
export default Vue.extend({
  name: "MValidationTranslate",
  methods: {
    doTranslateColumns<T extends ValidationTableType<T>>(
      values: T,
      translateKeys: PropsModel
    ): string {
      const messages: string[] = [];
      for (const key in values) {
        messages.push(this.$t(translateKeys[key]).toString());
      }
      return messages.join(", ");
    },
    doTranslateFields<T extends PropsModel>(
      fields: T,
      translateKeys: PropsModel
    ): string {
      const str: string[] = [];
      for (const key in fields) {
        str.push(this.$t(translateKeys[key]).toString());
      }
      return str.join(", ");
    },
  },
});
